.button{
  background: var(--primary);
  max-width: 520px;
  width: 100%;
  height: 68px;
  border: none;
  border-radius: 4px;
  color: white;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  margin: 10px 0 10px 0;
  transition: .3s;
  cursor: pointer;
}

.secondary {
  background: var(--white);
  color: var(--primary);
  border: 3px solid var(--primary);
}

.button:hover{
  background: var(--primary-lighter-hover);
  transition: .3s;
}

.secondary:hover {
  background-color: var(--primary-light);
}

.button:active{
  background: var(--primary-dark);
  transition: .3s;
}

.button:disabled {
  background-color: var(--primary-disabled);
}

@media only screen and (max-width: 600px) {
  .button{
    height: 58px;
  }
}