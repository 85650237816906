.error-message-container{
  padding: 30px;
  position: relative;
  background: white;
  border-radius: 10px;
  width: 100%;
  z-index: 30;
  margin-bottom: 20px;
  max-width: calc(100% - 40px);
}

.error-message-close-btn{
  position: absolute;
  top: 5px;
  right: 0;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
}

.close-icon{
  width: 30px;
  height: 30px;
}

.close-icon-sm{
  width: 25px;
  height: 25px;
}