.checkbox {
    width: 27px;
    height: 27px;
}

.captcha-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    align-self: flex-start;
}