.scroll-box {
    width: 100%;
    max-width: 520px;
    border: 2px solid var(--primary-lighter);
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    border-radius: 4px;
}

.scroll-box-content {
    overflow-y: scroll;
    max-width: 520px;
    height: 338px;
    font-size: 18px;
    font-weight: 500;
    margin: 10px 0 10px 0;
    padding: 20px;
    border-right: 10px solid var(--white);
    display: flex;
    flex-direction: column;
    /* not working for mozilla */
    scrollbar-color: var(--primary-lighter) !important;
    scrollbar-width: 8px !important;
}

@media only screen and (max-width: 600px) {
    .scroll-box-content {
        height: 246px;
    }
  }