.message-bubble {
  position: absolute;
  bottom: 20px;
  left: 20px;
  width: 100%;
  max-width: 248px;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  background: var(--white);
  box-shadow: 0px 2px 5px 0px #00000026;
  padding: 15px;
  z-index: 30;
  -webkit-animation: scale-in 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: scale-in 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.message-bubble:after {
  background: var(--white);
  box-shadow: 2px 2px 5px 0px #00000026;
  content: "\00a0";
  display: block;
  height: 20px;
  left: 90%;
  position: relative;
  top: 23px;
  transform:             rotate( 45deg );
      -moz-transform:    rotate( 45deg );
      -ms-transform:     rotate( 45deg );
      -o-transform:      rotate( 45deg );
      -webkit-transform: rotate( 45deg );
  width:  20px;
}

@media only screen and (max-width: 600px) {
  .message-bubble{
    display: none;
  }
}

@-webkit-keyframes scale-in {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}
@keyframes scale-in  {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}