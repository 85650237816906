.navigation-bar{
    width: 100%;
    height: 76px;
    background: white;
    display: grid;
    grid-template-columns: 1fr 5fr 1fr;
    align-items: center;
    box-shadow: 0px 4px 10px 0px #0000000F;
    z-index: 10;
    position: sticky;
    top: 0;
}

.navigation-bar-no-logo {
    width: 100%;
    height: 76px;
    background: white;
    display: flex;
    align-items: start;
    box-shadow: 0px 4px 10px 0px #0000000F;
    z-index: 10;
    position: sticky;
    top: 0;
    align-items: center;
}

.navigation-community-title{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: calc(80%);
    margin: auto;
    text-align: center;
}

.navigation-logo{
    padding-left: 30px;
    height: 24px;
}

.navigation-logo-community{
    padding-left: 30px;
    height: 40px;
}

.navigation-button{
    padding-right: 30px;
    width: 44px;
}

@Media only screen and (max-width: 900px) {
    .navigation-community-title{
        font-size: 24px;
    }
}

@media only screen and (max-width: 600px) {
    .navigation-button{
        width: 34px;
    }

    .navigation-community-title{
        font-size: 20px;
    }
}

@media screen and (max-width: 400px) {
    .navigation-community-title{
        font-size: 15px;
        margin: 5px;
    }
}

#ae_launcher.ae-cta-position-preset-right-upper {
top: 15px !important;

}