.checkbox-container{
  margin-bottom: 10px;
}

.error-container{
  margin-top: 10px;
}

.checkbox-children-container{
  display: flex;
  gap: 10px;
}