.message-container{
  padding: 20px;
  position: relative;
  border: 2px solid var(--primary-light);
  background: white;
  border-radius: 10px;
  width: 100%;
  z-index: 30;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.close-btn{
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  width: 20px;
  height: 20px;
}