.input-field{
  max-width: 520px;
  width: 100%;
  height: 64px;
  border: 2px solid var(--primary-lighter);
  outline: 0px solid var(--primary-lighter);
  border-radius: 4px;
  font-size: 18px;
  font-weight: 500;
  box-sizing: border-box;
  margin: 10px 0 10px 0;
  text-indent: 20px;
  font-weight: 700;
  font-size: 18px;
  transition: .1s;
 }

 .input-field:focus{
  border: 2px solid var(--primary-lighter);
  outline: 3px solid var(--primary-light);
 }
 
 .input-field::placeholder {
  color: var(--text);
  font-weight: 400;
  font-size: 18px;
 }
 
 .input-field-required {
  border-color: var(--error) !important;
 }
 
 .input-field-required::placeholder {
  color: var(--text);
  font-weight: 700;
  font-size: 18px;
 }
 
 .input-wrapper {
  width: 100%;
  max-width: 520px;
 }
 
 .input-wrapper-optional {
  position: relative;
  width: 100%;
  max-width: 520px;
 }
 
 .input-wrapper-optional::after {
  content: attr(data-aria-required);
  position: absolute;
  right: 8px;
  top: 50%;
  font-size: 15px;
  transform: translateY(-50%) translateX(-20%);
  color: var(--text);
  font-style: italic;
  font-weight: 400;
  font-size: 18px;
 }
 
 .input-wrapper-required {
  position: relative;
  width: 100%;
  max-width: 520px;
 }
 
 .input-wrapper-required::after {
  content: attr(data-aria-required);
  position: absolute;
  right: 8px;
  top: 50%;
  font-size: 15px;
  transform: translateY(-50%) translateX(-20%);
  color: var(--error);
  font-style: italic;
  font-weight: 700;
  font-size: 18px;
 }
 
  @media only screen and (max-width: 600px) {
  .input-field{
  height: 58px;
  }
  }

  .textarea {
    height: 90px;
    width: 100%;
    resize: none;

    max-width: 520px;
    border: 2px solid var(--primary-lighter);
    border-radius: 4px;
    font-size: 18px;
    font-weight: 500;
    box-sizing: border-box;
    margin: 10px 0 10px 0;
    padding: 10px 20px 10px 20px;
    text-indent: 0px;
    font-weight: 700;
    font-size: 18px;
  }

  .textarea::placeholder{
    color: var(--text);
    font-weight: 400;
    font-size: 18px;
  }

  .error-text{
    color: var(--error);
    margin-bottom: 10px;
  }