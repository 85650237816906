.progress-bar{
    display: flex;
    gap: 20px;
    padding-bottom: 20px;
    margin-top: auto;
}

.progress-bar-step-filled{
    width: 69px;
    height: 5px;
    border-radius: 60px;
    background: var(--primary);
}

.progress-bar-step-empty{
    width: 69px;
    height: 5px;
    border-radius: 60px;
    background: var(--primary-light);
}

@media only screen and (max-width: 600px) {
    .progress-bar{
        gap: 15px;
        padding-bottom: 15px;
    }

    .progress-bar-step-filled{
        width: 58px;
        height: 4.2px;
    }

    .progress-bar-step-empty{
        width: 58px;
        height: 4.2px;
    }
  }